import { useState } from "react";

export default function BonusBoomHook() {
    const [status, setStatus] = useState(false);
    const open = () => { setStatus(true) };
    const close = () => setStatus(false);
    const toggle = () => setStatus(!status);

    return {
        status: status,
        func: {
            open,
            close,
            toggle
        },
    };
}