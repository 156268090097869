import { Container, Modal } from "react-bootstrap";
import { t } from "i18next";
import i18next from "i18next";

import React, { useEffect, useState } from "react";

export const BonusBoom = (props) => {
    const cashierModal = props.data.CashierModal;
    const bonusBoom = props.data.BonusBoom;
    const helpers = props.data.helpers;
    const user = props.data.Authentication.user;

    const close = () => props.data.BonusBoom.func.close();

    if (!bonusBoom.status) return <></>

    return <Modal
        className="BonusBoom"
        size="lg"
        show={bonusBoom.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header closeButton>
            <Container className="text-center">
                <h4 className="title mb-0">{t('bonus_boom.title')}</h4>
            </Container>
        </Modal.Header>
        <Modal.Body className="p-4">
            {user.promotions.map((promotion, i) => (
                <div class="card mb-4">
                    <div class="card-body d-flex justify-content-between align-items-center">
                        <p class="notification-description mb-0">{helpers.t(promotion.title)}</p>
                        {/* create a button to open the promotion */}
                        <button type="button" class="btn btn-primary" onClick={() => cashierModal.func.deposit()}>{t('bonus_boom.deposit')}</button>
                    </div>
                </div>
            ))}
        </Modal.Body>
    </Modal>
}